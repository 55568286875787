import { Box } from "@mui/system";
import Icon from "../../../../UI/Icon";
import { showPrice } from "../../../../../utils/price";
import { GetPriceSeparator } from "../../../../../utils/prices";
import React, { useEffect, useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import {
    draggedStyle,
    useDraggableInPortal
} from "../../../../../utils/draggingFun";
import { usePrinterContext } from "../../../../../context/printer";
import { ItemRoot } from "./style";
import { renderToString } from "react-dom/server";
import { customItemEvents } from "../../../../../utils/customEvents";
import { isBlobUrl } from "../../../../../utils/common";
import { loadImage } from "../../../../../utils/loadImage";
import { SIDEBAR_TAB_INDEX } from "../../../../../const/general";

const ItemPriceData = ({ itemPriceStyle, item, restaurant, itemLayout }) => {
    return (
        <Box
            style={{
                ...itemPriceStyle,
                textAlign:
                    itemLayout?.layout === "priceBottom" ||
                    itemLayout?.layout === "priceLeft" ||
                    itemLayout?.layout === "priceWithDescSingleLineCenter"
                        ? "right"
                        : "",
                display:
                    itemLayout?.layout === "priceWithDescription" ||
                    itemLayout?.layout === "priceWithDescriptionCenter"
                        ? "inline"
                        : itemLayout?.layout === "priceRight" ||
                          itemLayout?.layout === "priceTopWithDescCenter"
                        ? "inline-block"
                        : "",
                paddingLeft:
                    itemLayout?.layout === "priceWithDescription" ||
                    itemLayout?.layout === "priceWithDescriptionCenter"
                        ? "2px"
                        : "",
                verticalAlign:
                    itemLayout?.layout === "priceRight" ||
                    itemLayout?.layout === "priceTopWithDescCenter"
                        ? "top"
                        : ""
            }}
            className="ItemPrice"
        >
            {item.priceType !== "modifier"
                ? item.prices.map((price, index) => (
                      <React.Fragment key={item.name + "item-price" + index}>
                          <ShowPriceData
                              price={price}
                              restaurant={restaurant}
                              itemLayout={itemLayout}
                          />
                          {index !== item?.prices?.length - 1 &&
                          ((itemLayout?.layout !== "priceWithDescription" &&
                              itemLayout?.layout !==
                                  "priceWithDescriptionCenter") ||
                              item?.priceType !== "standard")
                              ? GetPriceSeparator(item, itemLayout)
                              : " "}
                      </React.Fragment>
                  ))
                : item.prices.map((price, index) => {
                      return index === 0 ? (
                          <React.Fragment
                              key={item.name + "item-price" + index}
                          >
                              <ShowPriceData
                                  price={price}
                                  restaurant={restaurant}
                                  itemLayout={itemLayout}
                              />
                          </React.Fragment>
                      ) : (
                          ""
                      );
                  })}
        </Box>
    );
};

const ShowPriceData = ({ price, restaurant, itemLayout }) => {
    return (
        <>
            {showPrice(price, {
                currency_symbol: restaurant?.currency_symbol || "$",
                currency_placement: restaurant?.currency_placement || "before",
                show_currency_symbol: itemLayout?.displaySymbol,
                show_empty_decimals: itemLayout?.displayDecimal,
                show_item_price: itemLayout?.displayPrice
            })}
        </>
    );
};

const SortableItem = ({
    item,
    menu,
    restaurant,
    zoomLevel,
    allItemNameStyle,
    allItemDescriptionStyle,
    allItemPriceStyle,
    subSectionTitleStyle,
    subSectionTitleTopSpace,
    allItemLayout,
    columnCount,
    index,
    mirrorMode,
    pageIndex,
    rowIndex,
    columnIndex,
    blockIndex,
    focusId,
    setFocusId,
    itemId
}) => {
    const { showPreview, setTabIndex } = usePrinterContext();
    const { STYLES } = SIDEBAR_TAB_INDEX;
    const itemStyleEnabled = item?.styles?.enabled;
    const itemLayout = itemStyleEnabled
        ? item?.styles?.itemLayout
        : allItemLayout;
    const itemName = item?.styles?.itemName;
    const itemDescription = item?.styles?.itemDescription;
    const itemPrice = item?.styles?.itemPrice;
    const border = itemStyleEnabled ? item?.styles?.border : {};
    const padding = itemStyleEnabled ? item?.styles?.padding : {};
    const background = itemStyleEnabled ? item?.styles?.background : {};
    const displayIndent = itemStyleEnabled
        ? itemName?.displayIndent
        : allItemNameStyle?.displayIndent;

    const itemNameStyle = itemStyleEnabled
        ? {
              fontFamily: itemName?.fontFamily,
              fontWeight: itemName?.fontWeight,
              fontSize: itemName?.fontSize ? `${itemName?.fontSize}px` : "",
              lineHeight: itemName?.lineHeight
                  ? `${itemName?.lineHeight}px`
                  : "",
              fontStyle: itemName?.fontStyle,
              color: itemName?.color,
              letterSpacing: `${
                  itemName?.letterSpacing === 0
                      ? 0
                      : itemName?.letterSpacing / 10
              }px`,
              textTransform: itemName?.upperCase ? "uppercase" : "none"
          }
        : allItemNameStyle;

    const itemDescriptionStyle = itemStyleEnabled
        ? {
              fontFamily: itemDescription?.fontFamily,
              fontWeight: itemDescription?.fontWeight,
              fontSize: itemDescription?.fontSize
                  ? `${itemDescription?.fontSize}px`
                  : "",
              lineHeight: itemDescription?.lineHeight
                  ? `${itemDescription?.lineHeight}px`
                  : "",
              fontStyle: itemDescription?.fontStyle,
              color: itemDescription?.color,
              letterSpacing: `${
                  itemDescription?.letterSpacing === 0
                      ? 0
                      : itemDescription?.letterSpacing / 10
              }px`,
              textTransform: itemDescription?.upperCase ? "uppercase" : "none"
          }
        : allItemDescriptionStyle;

    const itemPriceStyle = itemStyleEnabled
        ? {
              fontFamily: itemPrice?.fontFamily,
              fontWeight:
                  // itemPrice?.boldFont === "bold" ? "bold" :
                  itemPrice?.fontWeight,
              fontSize: itemPrice?.fontSize ? `${itemPrice?.fontSize}px` : "",
              lineHeight: itemPrice?.lineHeight
                  ? `${itemPrice?.lineHeight}px`
                  : "",
              fontStyle: itemPrice?.fontStyle,
              color: itemPrice?.color,
              letterSpacing: `${
                  itemPrice?.letterSpacing === 0
                      ? 0
                      : itemPrice?.letterSpacing / 10
              }px`,
              textTransform: itemPrice?.upperCase ? "uppercase" : "none"
          }
        : allItemPriceStyle;

    // Border Styles
    const borderTop = border?.position?.top;
    const borderRight = border?.position?.right;
    const borderBottom = border?.position?.bottom;
    const borderLeft = border?.position?.left;
    const borderStyle = border?.style;
    const borderWidth = border?.width;
    const borderColor = border?.color || "#000000";
    const borderTopRadius = border?.radius?.top;
    const borderLeftRadius = border?.radius?.left;
    const borderRightRadius = border?.radius?.right;
    const borderBottomRadius = border?.radius?.bottom;

    const borderCSS = itemStyleEnabled
        ? {
              borderStyle: borderStyle,
              transition: "border 0.3s",
              borderColor: borderColor,
              borderTopWidth: borderTop ? borderWidth : 0,
              borderRightWidth: borderRight ? borderWidth : 0,
              borderBottomWidth: borderBottom ? borderWidth : 0,
              borderLeftWidth: borderLeft ? borderWidth : 0,
              borderTopLeftRadius: borderTopRadius,
              borderTopRightRadius: borderRightRadius,
              borderBottomRightRadius: borderBottomRadius,
              borderBottomLeftRadius: borderLeftRadius
          }
        : {};

    // Padding Style
    const paddingTop = padding?.top;
    const paddingRight = padding?.right;
    const paddingBottom = padding?.bottom;
    const paddingLeft = padding?.left;

    const paddingCSS = itemStyleEnabled
        ? {
              paddingTop: `${paddingTop}px`,
              paddingBottom: `${paddingBottom}px`,
              paddingLeft: `${paddingLeft}px`,
              paddingRight: `${paddingRight}px`
          }
        : {};

    // Background Style
    const backgroundUrl = background?.url;

    const [bgImageSrc, setBgImageSrc] = useState(backgroundUrl);

    useEffect(() => {
        if (backgroundUrl) {
            if (!isBlobUrl(backgroundUrl)) {
                loadImage(
                    backgroundUrl,
                    (image) => {
                        setBgImageSrc(image);
                    },
                    (error) => {
                        console.log(error);
                        setBgImageSrc(backgroundUrl);
                    }
                );
            } else {
                setBgImageSrc(backgroundUrl);
            }
        } else {
            setBgImageSrc(backgroundUrl);
        }
    }, [backgroundUrl]);

    const backgroundSize = backgroundUrl ? background?.size : "";
    const backgroundCover = background?.cover;
    const backgroundPos = background?.location;
    const backgroundColor =
        background?.color !== "#ffffff" ? background?.color : "";
    const backgroundTrans = background?.transparency / 100;

    const getItemStyle = (snapshot, provided) => {
        const {
            draggableStyle,
            isDragging,
            translateString,
            widthPos,
            heightPos,
            leftPos,
            topPos
        } = draggedStyle(snapshot, provided, zoomLevel);

        const scale = zoomLevel / 10;

        return {
            gridColumn:
                item.type === "title" && columnCount !== 1 ? "span 2" : "",
            textAlign:
                itemLayout?.layout === "priceCenter" ||
                itemLayout?.layout === "priceWithDescriptionCenter"
                    ? "center"
                    : "",
            marginTop:
                item.type === "title" ? `${subSectionTitleTopSpace}px` : "",
            backgroundColor: isDragging ? "#f4f4f4" : "",
            pointerEvents: showPreview ? "none" : "",
            ...draggableStyle,
            top: isDragging ? topPos : draggableStyle.top,
            left: isDragging ? leftPos : draggableStyle.left,
            width: isDragging ? widthPos : draggableStyle.width,
            height: isDragging ? heightPos : draggableStyle.height,
            transform: isDragging
                ? `${translateString} scale(${scale})`
                : translateString
        };
    };

    const renderDraggable = useDraggableInPortal();

    const itemDescriptionText = item?.description?.replace(/\n/g, "<br>");

    return (
        <>
            {!mirrorMode ? (
                <Draggable draggableId={item.id} index={index}>
                    {renderDraggable((provided, snapshot) => (
                        <ItemRoot
                            className={`ItemBlock ${
                                item.status !== "active" ? "draftItem" : ""
                            } ${snapshot.isDragging ? "dragging" : ""} ${
                                itemId === focusId ? "selectedItem" : ""
                            }`}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            style={getItemStyle(snapshot, provided)}
                        >
                            <Box
                                style={{
                                    ...borderCSS,
                                    ...paddingCSS,
                                    backgroundColor: backgroundColor,
                                    position: "relative",
                                    height: "100%"
                                }}
                            >
                                <Box
                                    className="backgroundImage absoluteBlock"
                                    style={{
                                        backgroundImage: bgImageSrc
                                            ? `url(${bgImageSrc})`
                                            : "",
                                        backgroundSize: bgImageSrc
                                            ? backgroundCover
                                                ? "cover"
                                                : backgroundSize + "%"
                                            : "",
                                        backgroundPosition: bgImageSrc
                                            ? backgroundPos
                                            : "",
                                        backgroundRepeat: bgImageSrc
                                            ? "no-repeat"
                                            : "",
                                        opacity: !isNaN(backgroundTrans)
                                            ? backgroundTrans
                                            : ""
                                    }}
                                ></Box>
                                <Box className="itemSort flexBox alignCenter justifyCenter hide_on_print">
                                    <span {...provided.dragHandleProps}>
                                        <Icon name="sort" />
                                    </span>
                                    {item.type !== "title" ? (
                                        <span
                                            className="edit-item"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                customItemEvents(
                                                    pageIndex,
                                                    rowIndex,
                                                    columnIndex,
                                                    blockIndex,
                                                    "item-styles",
                                                    index
                                                );
                                                setTabIndex(STYLES);
                                                setFocusId(
                                                    [
                                                        pageIndex,
                                                        rowIndex,
                                                        columnIndex,
                                                        blockIndex,
                                                        index
                                                    ].toString()
                                                );
                                            }}
                                        >
                                            <Icon name="editIcon" />
                                        </span>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                                <Box
                                    style={{
                                        position: "relative",
                                        height: "100%",
                                        zIndex: "1"
                                    }}
                                >
                                    <Box
                                        style={{
                                            display:
                                                itemLayout?.layout ===
                                                    "priceBottom" ||
                                                itemLayout?.layout ===
                                                    "priceWithDescSingleLineCenter"
                                                    ? "flex"
                                                    : "",
                                            justifyContent:
                                                itemLayout?.layout ===
                                                "priceBottom"
                                                    ? "space-between"
                                                    : itemLayout?.layout ===
                                                      "priceWithDescSingleLineCenter"
                                                    ? "center"
                                                    : "",
                                            height: !itemLayout?.displayNotation
                                                ? "100%"
                                                : "",
                                            textAlign:
                                                itemLayout?.layout ===
                                                "priceWithDescSingleLineCenter"
                                                    ? "center"
                                                    : ""
                                        }}
                                    >
                                        <Box
                                            style={{
                                                display:
                                                    itemLayout?.layout ===
                                                        "priceBottom" ||
                                                    itemLayout?.layout ===
                                                        "priceWithDescSingleLineCenter"
                                                        ? "flex"
                                                        : "",
                                                flexWrap:
                                                    itemLayout?.layout ===
                                                        "priceBottom" ||
                                                    itemLayout?.layout ===
                                                        "priceWithDescSingleLineCenter"
                                                        ? "wrap"
                                                        : "",
                                                width:
                                                    item.type === "title"
                                                        ? "100%"
                                                        : ""
                                            }}
                                        >
                                            <Box
                                                className="ItemTitle"
                                                style={{
                                                    display:
                                                        item.type === "title" ||
                                                        itemLayout?.layout ===
                                                            "priceCenter" ||
                                                        itemLayout?.layout ===
                                                            "priceWithDescriptionCenter"
                                                            ? "block"
                                                            : "",
                                                    justifyContent:
                                                        itemLayout?.layout ===
                                                        "priceRight"
                                                            ? "flex-start"
                                                            : itemLayout?.layout ===
                                                              "priceTopWithDescCenter"
                                                            ? "center"
                                                            : "",
                                                    width:
                                                        item.type === "title"
                                                            ? "100%"
                                                            : ""
                                                }}
                                            >
                                                <Box
                                                    className={`ItemName ${
                                                        item.type === "title"
                                                            ? "subSectionTitle"
                                                            : ""
                                                    }`}
                                                    style={{
                                                        ...(item.type ===
                                                        "title"
                                                            ? {
                                                                  ...subSectionTitleStyle,
                                                                  marginRight:
                                                                      itemLayout?.layout ===
                                                                          "priceCenter" ||
                                                                      itemLayout?.layout ===
                                                                          "priceRight" ||
                                                                      itemLayout?.layout ===
                                                                          "priceTopWithDescCenter" ||
                                                                      item.type ===
                                                                          "title"
                                                                          ? 0
                                                                          : ""
                                                              }
                                                            : {
                                                                  ...itemNameStyle,
                                                                  marginRight:
                                                                      itemLayout?.layout ===
                                                                          "priceCenter" ||
                                                                      itemLayout?.layout ===
                                                                          "priceRight" ||
                                                                      itemLayout?.layout ===
                                                                          "priceTopWithDescCenter" ||
                                                                      itemLayout?.layout ===
                                                                          "priceWithDescription" ||
                                                                      itemLayout?.layout ===
                                                                          "priceWithDescriptionCenter" ||
                                                                      item.type ===
                                                                          "title"
                                                                          ? 0
                                                                          : ""
                                                              }),
                                                        display:
                                                            (itemLayout?.layout ===
                                                                "priceBottom" ||
                                                                itemLayout?.layout ===
                                                                    "priceWithDescSingleLineCenter") &&
                                                            item.type !==
                                                                "title"
                                                                ? "flex"
                                                                : "",
                                                        flexWrap:
                                                            (itemLayout?.layout ===
                                                                "priceBottom" ||
                                                                itemLayout?.layout ===
                                                                    "priceWithDescSingleLineCenter") &&
                                                            item.type !==
                                                                "title"
                                                                ? "wrap"
                                                                : "",
                                                        textAlign:
                                                            itemLayout?.layout ===
                                                            "priceTopWithDescCenter"
                                                                ? "center"
                                                                : ""
                                                    }}
                                                >
                                                    <Box
                                                        style={{
                                                            display:
                                                                (itemLayout?.layout ===
                                                                    "priceRight" ||
                                                                    itemLayout?.layout ===
                                                                        "priceTopWithDescCenter") &&
                                                                item.type !==
                                                                    "title"
                                                                    ? "inline-block"
                                                                    : itemLayout?.layout ===
                                                                      "priceWithDescSingleLineCenter"
                                                                    ? "flex"
                                                                    : "",
                                                            marginRight:
                                                                itemLayout?.layout !==
                                                                    "priceRight" &&
                                                                itemLayout?.layout !==
                                                                    "priceTopWithDescCenter" &&
                                                                itemLayout?.layout !==
                                                                    "priceBottom" &&
                                                                itemLayout?.layout !==
                                                                    "priceWithDescSingleLineCenter" &&
                                                                itemLayout?.layout !==
                                                                    "priceWithDescription" &&
                                                                itemLayout?.layout !==
                                                                    "priceWithDescriptionCenter" &&
                                                                item.type !==
                                                                    "title"
                                                                    ? 8
                                                                    : ""
                                                        }}
                                                    >
                                                        <span
                                                            className={`${
                                                                displayIndent
                                                                    ? "indented-text"
                                                                    : ""
                                                            }`}
                                                            dangerouslySetInnerHTML={{
                                                                __html: item?.name
                                                            }}
                                                        />
                                                        {itemLayout?.layout ===
                                                            "priceRight" ||
                                                        itemLayout?.layout ===
                                                            "priceTopWithDescCenter"
                                                            ? " "
                                                            : ""}
                                                        {item?.description &&
                                                        itemLayout?.displayDescription &&
                                                        (itemLayout?.layout ===
                                                            "priceBottom" ||
                                                            itemLayout?.layout ===
                                                                "priceWithDescSingleLineCenter") ? (
                                                            <span
                                                                style={{
                                                                    ...itemDescriptionStyle,
                                                                    marginLeft:
                                                                        "8px"
                                                                }}
                                                                className="itemDescription"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: itemDescriptionText
                                                                }}
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                        {(itemLayout?.layout ===
                                                            "priceRight" ||
                                                            itemLayout?.layout ===
                                                                "priceTopWithDescCenter") &&
                                                        item.type !==
                                                            "title" ? (
                                                            <ItemPriceData
                                                                itemPriceStyle={
                                                                    itemPriceStyle
                                                                }
                                                                item={item}
                                                                restaurant={
                                                                    restaurant
                                                                }
                                                                itemLayout={
                                                                    itemLayout
                                                                }
                                                            />
                                                        ) : (
                                                            ""
                                                        )}
                                                    </Box>
                                                    {itemLayout?.layout !==
                                                        "priceBottom" &&
                                                    itemLayout?.layout !==
                                                        "priceWithDescSingleLineCenter" ? (
                                                        <Box>
                                                            {(() => {
                                                                const priceData =
                                                                    (
                                                                        <ItemPriceData
                                                                            itemPriceStyle={
                                                                                itemPriceStyle
                                                                            }
                                                                            item={
                                                                                item
                                                                            }
                                                                            restaurant={
                                                                                restaurant
                                                                            }
                                                                            itemLayout={
                                                                                itemLayout
                                                                            }
                                                                        />
                                                                    );

                                                                const priceDataString =
                                                                    renderToString(
                                                                        priceData
                                                                    );
                                                                const itemDescTextUpdate =
                                                                    item?.description &&
                                                                    itemLayout?.displayDescription
                                                                        ? itemDescriptionText
                                                                        : "";
                                                                return (
                                                                    <p
                                                                        style={
                                                                            itemDescriptionStyle
                                                                        }
                                                                        className={`itemDescription`}
                                                                        dangerouslySetInnerHTML={{
                                                                            __html: `${itemDescTextUpdate} ${
                                                                                priceDataString &&
                                                                                (itemLayout?.layout ===
                                                                                    "priceWithDescription" ||
                                                                                    itemLayout?.layout ===
                                                                                        "priceWithDescriptionCenter")
                                                                                    ? priceDataString
                                                                                    : ""
                                                                            }`
                                                                        }}
                                                                    />
                                                                );
                                                            })()}
                                                        </Box>
                                                    ) : (
                                                        ""
                                                    )}
                                                </Box>
                                                {item?.prices.length !== 0 &&
                                                itemLayout?.layout !==
                                                    "priceWithDescription" &&
                                                itemLayout?.layout !==
                                                    "priceWithDescriptionCenter" &&
                                                itemLayout?.layout !==
                                                    "priceCenter" &&
                                                itemLayout?.layout !==
                                                    "priceRight" &&
                                                itemLayout?.layout !==
                                                    "priceTopWithDescCenter" &&
                                                item.type !== "title" &&
                                                itemLayout?.layout !==
                                                    "priceBottom" &&
                                                itemLayout?.layout !==
                                                    "priceWithDescSingleLineCenter" ? (
                                                    <ItemPriceData
                                                        itemPriceStyle={
                                                            itemPriceStyle
                                                        }
                                                        item={item}
                                                        restaurant={restaurant}
                                                        itemLayout={itemLayout}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </Box>
                                            {item?.prices.length !== 0 ? (
                                                <p
                                                    style={itemDescriptionStyle}
                                                    className="itemDescription modifierPrice"
                                                >
                                                    {item?.priceType ===
                                                    "modifier"
                                                        ? item.prices
                                                              .slice(1)
                                                              .map(
                                                                  (
                                                                      price,
                                                                      index
                                                                  ) => {
                                                                      return (
                                                                          <React.Fragment
                                                                              key={
                                                                                  item.name +
                                                                                  "item-price" +
                                                                                  index
                                                                              }
                                                                          >
                                                                              <ShowPriceData
                                                                                  price={
                                                                                      price
                                                                                  }
                                                                                  restaurant={
                                                                                      restaurant
                                                                                  }
                                                                                  itemLayout={
                                                                                      itemLayout
                                                                                  }
                                                                              />
                                                                              {index !==
                                                                                  item
                                                                                      ?.prices
                                                                                      ?.length -
                                                                                      2 &&
                                                                                  GetPriceSeparator(
                                                                                      item,
                                                                                      itemLayout
                                                                                  )}
                                                                          </React.Fragment>
                                                                      );
                                                                  }
                                                              )
                                                        : ""}
                                                </p>
                                            ) : (
                                                ""
                                            )}
                                        </Box>
                                        {itemLayout?.layout === "priceCenter" ||
                                        ((itemLayout?.layout ===
                                            "priceBottom" ||
                                            itemLayout?.layout ===
                                                "priceWithDescSingleLineCenter") &&
                                            item.type !== "title") ? (
                                            <ItemPriceData
                                                itemPriceStyle={itemPriceStyle}
                                                item={item}
                                                restaurant={restaurant}
                                                itemLayout={itemLayout}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </Box>
                                    {item?.notations.length !== 0 &&
                                    itemLayout?.displayNotation ? (
                                        <Box className="ItemsNotations">
                                            {item?.notations?.map(
                                                (notation, index) => {
                                                    return (
                                                        <span
                                                            style={{
                                                                fontSize: `${itemLayout?.notationSize}px`
                                                            }}
                                                            key={index}
                                                        >
                                                            {
                                                                notation.display_name
                                                            }
                                                        </span>
                                                    );
                                                }
                                            )}
                                        </Box>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                            </Box>
                            {provided.placeholder}
                        </ItemRoot>
                    ))}
                </Draggable>
            ) : (
                <ItemRoot
                    className={`ItemBlock ${
                        item.status === "draft" ||
                        item.status === "delete" ||
                        item.status === "trash"
                            ? "draftItem"
                            : ""
                    }`}
                    style={{
                        gridColumn:
                            item.type === "title" && columnCount !== 1
                                ? "span 2"
                                : "",
                        textAlign:
                            itemLayout?.layout === "priceCenter" ||
                            itemLayout?.layout === "priceWithDescriptionCenter"
                                ? "center"
                                : "",
                        marginTop:
                            item.type === "title"
                                ? `${subSectionTitleTopSpace}px`
                                : ""
                    }}
                >
                    <Box
                        style={{
                            ...borderCSS,
                            ...paddingCSS,
                            backgroundColor: backgroundColor,
                            position: "relative",
                            height: "100%"
                        }}
                    >
                        <Box
                            className="backgroundImage absoluteBlock"
                            style={{
                                backgroundImage: bgImageSrc
                                    ? `url(${bgImageSrc})`
                                    : "",
                                backgroundSize: bgImageSrc
                                    ? backgroundCover
                                        ? "cover"
                                        : backgroundSize + "%"
                                    : "",
                                backgroundPosition: bgImageSrc
                                    ? backgroundPos
                                    : "",
                                backgroundRepeat: bgImageSrc ? "no-repeat" : "",
                                opacity: !isNaN(backgroundTrans)
                                    ? backgroundTrans
                                    : ""
                            }}
                        ></Box>
                        <Box
                            style={{
                                position: "relative",
                                height: "100%",
                                zIndex: "1"
                            }}
                        >
                            <Box
                                style={{
                                    display:
                                        itemLayout?.layout === "priceBottom" ||
                                        itemLayout?.layout ===
                                            "priceWithDescSingleLineCenter"
                                            ? "flex"
                                            : "",
                                    justifyContent:
                                        itemLayout?.layout === "priceBottom"
                                            ? "space-between"
                                            : itemLayout?.layout ===
                                              "priceWithDescSingleLineCenter"
                                            ? "center"
                                            : "",
                                    height: !itemLayout?.displayNotation
                                        ? "100%"
                                        : "",
                                    textAlign:
                                        itemLayout?.layout ===
                                        "priceWithDescSingleLineCenter"
                                            ? "center"
                                            : ""
                                }}
                            >
                                <Box
                                    style={{
                                        display:
                                            itemLayout?.layout ===
                                                "priceBottom" ||
                                            itemLayout?.layout ===
                                                "priceWithDescSingleLineCenter"
                                                ? "flex"
                                                : "",
                                        flexWrap:
                                            itemLayout?.layout ===
                                                "priceBottom" ||
                                            itemLayout?.layout ===
                                                "priceWithDescSingleLineCenter"
                                                ? "wrap"
                                                : "",
                                        width:
                                            item.type === "title" ? "100%" : ""
                                    }}
                                >
                                    <Box
                                        className="ItemTitle"
                                        style={{
                                            display:
                                                item.type === "title" ||
                                                itemLayout?.layout ===
                                                    "priceCenter" ||
                                                itemLayout?.layout ===
                                                    "priceWithDescriptionCenter"
                                                    ? "block"
                                                    : "",
                                            justifyContent:
                                                itemLayout?.layout ===
                                                "priceRight"
                                                    ? "flex-start"
                                                    : itemLayout?.layout ===
                                                      "priceTopWithDescCenter"
                                                    ? "center"
                                                    : "",
                                            width:
                                                item.type === "title"
                                                    ? "100%"
                                                    : ""
                                        }}
                                    >
                                        <Box
                                            className={`ItemName ${
                                                item.type === "title"
                                                    ? "subSectionTitle"
                                                    : ""
                                            }`}
                                            style={{
                                                ...(item.type === "title"
                                                    ? {
                                                          ...subSectionTitleStyle,
                                                          marginRight:
                                                              itemLayout?.layout ===
                                                                  "priceCenter" ||
                                                              itemLayout?.layout ===
                                                                  "priceRight" ||
                                                              itemLayout?.layout ===
                                                                  "priceTopWithDescCenter" ||
                                                              item.type ===
                                                                  "title"
                                                                  ? 0
                                                                  : ""
                                                      }
                                                    : {
                                                          ...itemNameStyle,
                                                          marginRight:
                                                              itemLayout?.layout ===
                                                                  "priceCenter" ||
                                                              itemLayout?.layout ===
                                                                  "priceRight" ||
                                                              itemLayout?.layout ===
                                                                  "priceTopWithDescCenter" ||
                                                              itemLayout?.layout ===
                                                                  "priceWithDescription" ||
                                                              itemLayout?.layout ===
                                                                  "priceWithDescriptionCenter" ||
                                                              item.type ===
                                                                  "title"
                                                                  ? 0
                                                                  : ""
                                                      }),
                                                display:
                                                    (itemLayout?.layout ===
                                                        "priceBottom" ||
                                                        itemLayout?.layout ===
                                                            "priceWithDescSingleLineCenter") &&
                                                    item.type !== "title"
                                                        ? "flex"
                                                        : "",
                                                flexWrap:
                                                    (itemLayout?.layout ===
                                                        "priceBottom" ||
                                                        itemLayout?.layout ===
                                                            "priceWithDescSingleLineCenter") &&
                                                    item.type !== "title"
                                                        ? "wrap"
                                                        : "",
                                                textAlign:
                                                    itemLayout?.layout ===
                                                    "priceTopWithDescCenter"
                                                        ? "center"
                                                        : ""
                                            }}
                                        >
                                            <Box
                                                style={{
                                                    display:
                                                        (itemLayout?.layout ===
                                                            "priceRight" ||
                                                            itemLayout?.layout ===
                                                                "priceTopWithDescCenter") &&
                                                        item.type !== "title"
                                                            ? "inline-block"
                                                            : itemLayout?.layout ===
                                                              "priceWithDescSingleLineCenter"
                                                            ? "flex"
                                                            : "",
                                                    marginRight:
                                                        itemLayout?.layout !==
                                                            "priceRight" &&
                                                        itemLayout?.layout !==
                                                            "priceTopWithDescCenter" &&
                                                        itemLayout?.layout !==
                                                            "priceBottom" &&
                                                        itemLayout?.layout !==
                                                            "priceWithDescSingleLineCenter" &&
                                                        itemLayout?.layout !==
                                                            "priceWithDescription" &&
                                                        itemLayout?.layout !==
                                                            "priceWithDescriptionCenter" &&
                                                        item.type !== "title"
                                                            ? 8
                                                            : ""
                                                }}
                                            >
                                                <span
                                                    className={`${
                                                        displayIndent
                                                            ? "indented-text"
                                                            : ""
                                                    }`}
                                                    dangerouslySetInnerHTML={{
                                                        __html: item?.name
                                                    }}
                                                />
                                                {itemLayout?.layout ===
                                                    "priceRight" ||
                                                itemLayout?.layout ===
                                                    "priceTopWithDescCenter"
                                                    ? " "
                                                    : ""}
                                                {(item?.description &&
                                                    itemLayout?.displayDescription &&
                                                    itemLayout?.layout ===
                                                        "priceBottom") ||
                                                itemLayout?.layout ===
                                                    "priceWithDescSingleLineCenter" ? (
                                                    <span
                                                        style={{
                                                            ...itemDescriptionStyle,
                                                            marginLeft: "8px"
                                                        }}
                                                        className="itemDescription"
                                                        dangerouslySetInnerHTML={{
                                                            __html: itemDescriptionText
                                                        }}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                                {(itemLayout?.layout ===
                                                    "priceRight" ||
                                                    itemLayout?.layout ===
                                                        "priceTopWithDescCenter") &&
                                                item.type !== "title" ? (
                                                    <ItemPriceData
                                                        itemPriceStyle={
                                                            itemPriceStyle
                                                        }
                                                        item={item}
                                                        restaurant={restaurant}
                                                        itemLayout={itemLayout}
                                                    />
                                                ) : (
                                                    ""
                                                )}
                                            </Box>
                                            {itemLayout?.layout !==
                                                "priceBottom" &&
                                            itemLayout?.layout !==
                                                "priceWithDescSingleLineCenter" ? (
                                                <Box>
                                                    {(() => {
                                                        const priceData = (
                                                            <ItemPriceData
                                                                itemPriceStyle={
                                                                    itemPriceStyle
                                                                }
                                                                item={item}
                                                                restaurant={
                                                                    restaurant
                                                                }
                                                                itemLayout={
                                                                    itemLayout
                                                                }
                                                            />
                                                        );

                                                        const priceDataString =
                                                            renderToString(
                                                                priceData
                                                            );
                                                        const itemDescTextUpdate =
                                                            item?.description &&
                                                            itemLayout?.displayDescription
                                                                ? itemDescriptionText
                                                                : "";
                                                        return (
                                                            <p
                                                                style={
                                                                    itemDescriptionStyle
                                                                }
                                                                className={`itemDescription`}
                                                                dangerouslySetInnerHTML={{
                                                                    __html: `${itemDescTextUpdate} ${
                                                                        priceDataString &&
                                                                        (itemLayout?.layout ===
                                                                            "priceWithDescription" ||
                                                                            itemLayout?.layout ===
                                                                                "priceWithDescriptionCenter")
                                                                            ? priceDataString
                                                                            : ""
                                                                    }`
                                                                }}
                                                            />
                                                        );
                                                    })()}
                                                </Box>
                                            ) : (
                                                ""
                                            )}
                                        </Box>
                                        {item?.prices.length !== 0 &&
                                        itemLayout?.layout !==
                                            "priceWithDescription" &&
                                        itemLayout?.layout !==
                                            "priceWithDescriptionCenter" &&
                                        itemLayout?.layout !== "priceCenter" &&
                                        itemLayout?.layout !== "priceRight" &&
                                        itemLayout?.layout !==
                                            "priceTopWithDescCenter" &&
                                        item.type !== "title" &&
                                        itemLayout?.layout !== "priceBottom" &&
                                        itemLayout?.layout !==
                                            "priceWithDescSingleLineCenter" ? (
                                            <ItemPriceData
                                                itemPriceStyle={itemPriceStyle}
                                                item={item}
                                                restaurant={restaurant}
                                                itemLayout={itemLayout}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </Box>
                                    {item?.prices.length !== 0 ? (
                                        <p
                                            style={itemDescriptionStyle}
                                            className="itemDescription modifierPrice"
                                        >
                                            {item?.priceType === "modifier"
                                                ? item.prices
                                                      .slice(1)
                                                      .map((price, index) => {
                                                          return (
                                                              <React.Fragment
                                                                  key={
                                                                      item.name +
                                                                      "item-price" +
                                                                      index
                                                                  }
                                                              >
                                                                  <ShowPriceData
                                                                      price={
                                                                          price
                                                                      }
                                                                      restaurant={
                                                                          restaurant
                                                                      }
                                                                      itemLayout={
                                                                          itemLayout
                                                                      }
                                                                  />
                                                                  {index !==
                                                                      item
                                                                          ?.prices
                                                                          ?.length -
                                                                          2 &&
                                                                      GetPriceSeparator(
                                                                          item,
                                                                          itemLayout
                                                                      )}
                                                              </React.Fragment>
                                                          );
                                                      })
                                                : ""}
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                </Box>
                                {itemLayout?.layout === "priceCenter" ||
                                ((itemLayout?.layout === "priceBottom" ||
                                    itemLayout?.layout ===
                                        "priceWithDescSingleLineCenter") &&
                                    item.type !== "title") ? (
                                    <ItemPriceData
                                        itemPriceStyle={itemPriceStyle}
                                        item={item}
                                        restaurant={restaurant}
                                        itemLayout={itemLayout}
                                    />
                                ) : (
                                    ""
                                )}
                            </Box>
                            {item?.notations.length !== 0 &&
                            itemLayout?.displayNotation ? (
                                <Box className="ItemsNotations">
                                    {item?.notations?.map((notation, index) => {
                                        return (
                                            <span
                                                style={{
                                                    fontSize: `${itemLayout?.notationSize}px`
                                                }}
                                                key={index}
                                            >
                                                {notation.display_name}
                                            </span>
                                        );
                                    })}
                                </Box>
                            ) : (
                                ""
                            )}
                        </Box>
                    </Box>
                </ItemRoot>
            )}
        </>
    );
};

export default SortableItem;
