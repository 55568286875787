import { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { ImageCardStyles } from "../../../../../../UI/CommonStyles";
import {
    FontSize,
    FormSwitch,
    ImageCard
} from "../../../../../../UI/CommonComponents";
import { usePrinterContext } from "../../../../../../../context/printer";
import { cloneDeep, isEqual } from "lodash";

const columnState = [
    {
        label: "",
        layout: "priceLeft",
        iconName: "itemLayoutLeft"
    },
    {
        label: "",
        layout: "priceRight",
        iconName: "itemLayoutRight"
    },
    {
        label: "",
        layout: "priceBottom",
        iconName: "itemLayoutBottom"
    },
    {
        label: "",
        layout: "priceWithDescription",
        iconName: "itemLayoutWithDesc"
    },
    {
        label: "",
        layout: "priceCenter",
        iconName: "itemLayoutCenter"
    },
    {
        label: "",
        layout: "priceWithDescriptionCenter",
        iconName: "itemLayoutWithDescCenter"
    },
    {
        label: "",
        layout: "priceTopWithDescCenter",
        iconName: "itemLayoutCenterPriceTop"
    },
    {
        label: "",
        layout: "priceWithDescSingleLineCenter",
        iconName: "itemLayoutSingleLineCenter"
    }
];

export default function ItemLayout({ data }) {
    const {
        pageBlocks,
        setPageBlocks,
        sectionBlock,
        setSectionBlock,
        undoRedoState,
        pageBlocksIndex,
        pageBlocksArray,
        revertItemStyle
    } = usePrinterContext();
    const [dependencyChanged, setDependencyChanged] = useState(false);

    const updatedPageBlocks = [...pageBlocks];
    const blockToUpdate =
        updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
            data.columnIndex
        ]?.blocks[data.blockIndex]?.data?.items?.items[data?.itemIndex];

    const [itemStyleEnabled, setItemStyleEnabled] = useState(
        blockToUpdate?.styles?.enabled
    );
    const [fontSize, setFontSize] = useState(
        blockToUpdate?.styles?.itemLayout?.notationSize
    );
    const [layout, setLayout] = useState(
        blockToUpdate?.styles?.itemLayout?.layout
    );
    const [displayDescription, setDisplayDescription] = useState(
        blockToUpdate?.styles?.itemLayout?.displayDescription
    );
    const [displayDecimal, setDisplayDecimal] = useState(
        blockToUpdate?.styles?.itemLayout?.displayDecimal
    );
    const [displayPrice, setDisplayPrice] = useState(
        blockToUpdate?.styles?.itemLayout?.displayPrice
    );
    const [displaySymbol, setDisplaySymbol] = useState(
        blockToUpdate?.styles?.itemLayout?.displaySymbol
    );
    const [displayNotation, setDisplayNotation] = useState(
        blockToUpdate?.styles?.itemLayout?.displayNotation
    );

    useEffect(() => {
        if (
            undoRedoState?.state &&
            isEqual(data, sectionBlock.data) &&
            revertItemStyle
        ) {
            return;
        }

        setItemStyleEnabled(blockToUpdate?.styles?.enabled);
        setFontSize(blockToUpdate?.styles?.itemLayout?.notationSize);
        setLayout(blockToUpdate?.styles?.itemLayout?.layout);
        setDisplayDecimal(blockToUpdate?.styles?.itemLayout?.displayDecimal);
        setDisplayDescription(
            blockToUpdate?.styles?.itemLayout?.displayDescription
        );
        setDisplaySymbol(blockToUpdate?.styles?.itemLayout?.displaySymbol);
        setDisplayPrice(blockToUpdate?.styles?.itemLayout?.displayPrice);
        setDisplayNotation(blockToUpdate?.styles?.itemLayout?.displayNotation);

        setSectionBlock({ data: data, pageBlocks: pageBlocks });

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        data.pageIndex,
        data.rowIndex,
        data.columnIndex,
        data.blockIndex,
        data.itemIndex,
        undoRedoState,
        revertItemStyle
    ]);

    useEffect(() => {
        if (!itemStyleEnabled && dependencyChanged) {
            setItemStyleEnabled(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dependencyChanged]);

    useEffect(() => {
        const clonedPageBlocksArray = cloneDeep(pageBlocksArray);
        const historyBlocks = clonedPageBlocksArray[pageBlocksIndex.current];

        if (isEqual(pageBlocks, historyBlocks) && !dependencyChanged) {
            return;
        }

        const newStyles = {
            layout,
            notationSize: fontSize,
            displayDecimal,
            displayDescription,
            displayNotation,
            displayPrice,
            displaySymbol
        };

        const updatedPageBlocks = [...pageBlocks];
        const blockToUpdate =
            updatedPageBlocks[data.pageIndex]?.row[data?.rowIndex]?.column[
                data.columnIndex
            ]?.blocks[data.blockIndex]?.data?.items?.items[data?.itemIndex];

        if (blockToUpdate) {
            blockToUpdate.styles.itemLayout = {
                ...blockToUpdate.styles.itemLayout,
                ...newStyles
            };

            blockToUpdate.styles.enabled = itemStyleEnabled;

            // Update the state with the modified pageBlocks
            setPageBlocks(updatedPageBlocks);
        }
        setSectionBlock({
            pageBlocks: updatedPageBlocks
        });
        setDependencyChanged(false);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        layout,
        fontSize,
        displayDecimal,
        displayDescription,
        displayNotation,
        displayPrice,
        displaySymbol,
        itemStyleEnabled
    ]);

    const handleOnClick = (layout) => {
        setDependencyChanged(true);
        setLayout(layout);
    };

    return (
        <div className="collapseContentWrapper itemLayout">
            <ImageCardStyles className="imageCardWrap hasTwoCol mb-0">
                {columnState.map((item, index) => (
                    <ImageCard
                        key={index}
                        icon={item.iconName}
                        label={item.label}
                        onClick={() => handleOnClick(item.layout)}
                        isActive={layout === item.layout}
                    />
                ))}
            </ImageCardStyles>
            <FormSwitch
                value={displayDescription}
                setValue={setDisplayDescription}
                label="Display Item Description"
                setDependencyChanged={setDependencyChanged}
            />
            <FormSwitch
                value={displayPrice}
                setValue={setDisplayPrice}
                label="Display Item Price"
                setDependencyChanged={setDependencyChanged}
            />
            <FormSwitch
                value={displayDecimal}
                setValue={setDisplayDecimal}
                label="Display Empty Decimals (.00)"
                setDependencyChanged={setDependencyChanged}
            />
            <FormSwitch
                value={displaySymbol}
                setValue={setDisplaySymbol}
                label="Display Currency Symbol ($)"
                setDependencyChanged={setDependencyChanged}
            />
            <Box style={{ position: "relative" }}>
                <FormSwitch
                    value={displayNotation}
                    setValue={setDisplayNotation}
                    label="Display Allergies & Notations"
                    setDependencyChanged={setDependencyChanged}
                />
                <Box style={{ position: "absolute", right: 0, bottom: 5 }}>
                    <FontSize
                        fontSize={fontSize}
                        setFontSize={setFontSize}
                        setDependencyChanged={setDependencyChanged}
                    />
                </Box>
            </Box>
        </div>
    );
}
